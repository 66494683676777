import { useTranslation } from "react-i18next";
import * as Yup from "yup";

Yup.addMethod(Yup.string, "creditCard", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value && value.length === 16) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "confirmPassword", function (errorMessage) {
  return this.test(`test-confirm-password`, errorMessage, function (value) {
    const { path, parent, createError } = this;

    return (
      value === parent.password || createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "phoneNumber", function (errorMessage) {
  return this.test(`test-phone-number`, errorMessage, function (value) {
    const { path, createError } = this;

    // for example tr number or ksa or uae phone without phone code
    return (
      (value &&
        /^(5\d{9}|05\d{9}|009665\d{8}|9665\d{8}|5\d{8})$/.test(value)) ||
      createError({ path, message: errorMessage })
    );
    // return (
    //   (value &&
    //     /^(\+?\d{1,4}[\s-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(
    //       value
    //     )) ||
    //   createError({ path, message: errorMessage })
    // );
  });
});

Yup.addMethod(Yup.string, "email", function (errorMessage) {
  return this.test(`test-email`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

export const useYup = () => {
  const { t } = useTranslation();

  Yup.setLocale({
    mixed: {
      default: () => t("validation.default"),
      required: () => t("validation.required"),
      notOneOf: ({ values }) => t("validation.notoneof", { values }),
    },
    string: {
      length: ({ length }) => t("validation.length", { length }),
      min: ({ min }) => t("validation.min", { min }),
      max: ({ max }) => t("validation.max", { max }),
      matches: ({ regex }) => t("validation.matches", { regex: `${regex}` }),
      email: () => t("validation.email"),
      url: () => t("validation.url"),
      trim: () => t("validation.trim"),
      lowercase: () => t("validation.lowercase"),
      uppercase: () => t("validation.loweuppercasercase"),
    },
    number: {
      min: ({ min }) => t("validation.min", { min }),
      max: ({ max }) => t("validation.max", { max }),
      lessThan: ({ less }) => t("validation.less", { less }),
      moreThan: ({ more }) => t("validation.more", { more }),
      positive: () => t("validation.positive"),
      negative: () => t("validation.negative"),
      integer: () => t("validation.integer"),
    },
    date: {
      min: ({ min }) => t("validation.date-min", { min }),
      max: ({ max }) => t("validation.date-max", { max }),
    },
    object: {
      noUnknown: () => t("validation.object.noUnknown"),
    },
    array: {
      min: ({ min }) => t("validation.array-min", { min }),
      max: ({ max }) => t("validation.array-max", { max }),
    },
  });

  // add methods here

  return { Yup };
};
